<!--
 * @Description: 
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-07-21 09:40:08
-->
<template>
  <div class="order-box">
    <component :is="componentName"></component>
  </div>
</template>

<script>
import mixins from '@/utils/mixins'
export default {
  name: 'orderPage',
  mixins: [mixins],
  data() {
    return {
      componentName: ''
    }
  },
  created() {
    this.initDom()
  },
  methods: {
    initDom() {
      switch (this.$templates()) {
        case 'themeTemplateOne':
          this.componentName = 'pageOrderOne'
          break
        case 'themeTemplateTow':
          this.componentName = 'pageOrderTow'
          break
        case 'themeTemplateThree':
          this.componentName = 'pageOrderThree'
          break
        case 'themeTemplateFour':
          this.componentName = 'pageOrderFour'
          break
        case 'themeTemplateFive':
          this.componentName = 'pageOrderFive'
          break
        case 'themeTemplateSix':
          this.componentName = 'pageOrderSix'
          break
        case 'themeTemplateSeven':
          this.componentName = 'pageOrderSeven'
          break
        case 'themeTemplateEight':
          this.componentName = 'pageOrderEight'
          break
        default:
          this.componentName = ''
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.order-box {
  width: 100%;
}
</style>