/*
 * @Description: 德科英文
 * @Author: zml
 * @Date: 2023-03-23 14:52:59
 * @LastEditors: zml
 * @LastEditTime: 2023-12-12 14:13:17
 */
export default {
    "DK.navTitle.Home":"Home",
    "DK.navTitle.Produch":"Product",
    "DK.navTitle.Cart":"Shopping Cart",
    "DK.navTitle.Exhibition":"Exhibition Plan",
    "DK.navTitle.Message":"Contact / About Us",
    "DK.logoBox.placeholder":"Please enter the product keyword",
    "DK.ProductNumber":"Item NO.",
    "DK.amount":"Quantity",
    "DK.contactUs":"Contact Us",
    "DK.understandDeke":"About Us",
    "DK.telephone":"Telephone",
    "DK.phoneAndWeixin":"Phone & Wechat",
    "DK.PleaseOrderNum":"Please enter the order number",
    "DK.ArticleNo":"Item No.",
    "DK.TotalVolume":"Total CBM",
    "DK.totalQTY": "Total QTY",
    "DK.totalItems": "Total Items",
    "DK.ExhibitionRelatedProducts":"Exhibition related products",
    "DK.ProductsVideo":"Products Video",
    "DK.ReleaseDate":"Release Date",
    "DK.inquirySheet":"Inquiry List",
    "DK.SendInquiry":"Add to inquiry",
    "DK.productPrice":"Price",
    "DK.OuterBoxCBM":"CBM",
    "DK.OuterBoxCUFT":"CUFT",
    "DK.Innerboxes":"Inner Boxes",
    "DK.OuterCapacity":"Outer QTY",
    "DK.productDetails":"Product details",
    "DK.similarProduct":"Similar product",
    "DK.MyRFQ":"My RFQ",
    "DK.enquiry":"Enquiry",
    "DK.enquirys": "Leave your email and we'll get back to you",
    "DK.contact":"Contact Person",
    "DK.Description":"Description",
    "DK.OuterQTY":"Outer QTY",
    "DK.LastSevenDays": "Seven Days",
    "DK.All": "All",
    "DK.PleaseEmial": "Please enter your email",
    "DK.PleaseCode": "Please enter the vailde code",
    "DK.PleasePhone": "Please enter your mobile phone number",
    "DK.PleaseName": "Please enter your username",
    "DK.userName": "Username",
    "DK.PleaseAddress": "Please enter your contact address",
    "DK.HighRec": 'Hot Products',
}   
