<!--
 * @Description: 新闻（展会计划）详情
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-09-11 12:24:35
-->
<template>
  <div>
    <component :is="componentName"></component>
  </div>
</template>
<script>
import mixins from '@/utils/mixins'
export default {
  name: 'newDetail',
  mixins: [mixins],
  data() {
    return {
      componentName: ''
    }
  },
  created() {
    this.initDom()
  },
  methods: {
    initDom() {
      switch (this.$templates()) {
        case 'themeTemplateThree':
          this.componentName = 'newDetailThree'
          break
        case 'themeTemplateSix':
          this.componentName = 'newDetailSix'
          break
        case 'themeTemplateSeven':
          this.componentName = 'newDetailSeven'
          break
        case 'themeTemplateEight':
          this.componentName = 'newDetailEight'
          break
        default:
          this.componentName = ''
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>