<!--
 * @Description: 联系我们
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-09-11 12:25:58
-->
<template>
  <div>
    <component :datas="contactData" :is="componentName"></component>
  </div>
</template>
<script>
import { GetContactInfo } from '@/api/home'
import mixins from '@/utils/mixins'
export default {
  name: 'contactPage',
  mixins: [mixins],

  data() {
    return {
      contactData: {},
      componentName: ''
    }
  },
  created() {
    this.init()
    this.initDom()
  },
  methods: {
    initDom() {
      switch (this.$templates()) {
        case 'themeTemplateOne':
          this.componentName = 'contactDetailOne'
          break
        case 'themeTemplateTow':
          this.componentName = 'contactDetailTow'
          break
        case 'themeTemplateThree':
          this.componentName = 'contactDetailThree'
          break
        case 'themeTemplateFour':
          this.componentName = 'contactDetailFour'
          break
        case 'themeTemplateFive':
          this.componentName = 'contactDetailFive'
          break
        case 'themeTemplateSix':
          this.componentName = 'contactDetailSix'
          break
        case 'themeTemplateSeven':
          this.componentName = 'contactDetailSeven'
          break
        case 'themeTemplateEight':
          this.componentName = 'contactDetailEight'
          break
        default:
          this.componentName = ''
          break
      }
    },
    init() {
      GetContactInfo().then(res => {
        if (res.success) {
          this.contactData = res.data
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>